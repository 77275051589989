<template>
  <div class="row">&nbsp;</div>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="row">
          <welcomecard />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card">
      <div v-if="this.is_authorization === null" class="card-body">
        <div class="d-flex justify-content-center">
          authorization checking...
        </div>
      </div>
      <div v-if="this.is_authorization === 'N'" class="card-body">
        <div class="d-flex justify-content-center">
          You do not have authorization for this page
        </div>
      </div>
      <div v-if="this.is_authorization=== 'Y'" class="card-body">

        <div v-if="this.eventDetails.payment_method === 'DIRECT'" class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <b>[{{this.eventDetails.title }}][ADMIN]</b>
              <div class="header-top">
                <h5 class="m-0"></h5>
                <form class="form-inline">
                  <div class="form-group">
                    <select class="form-select" v-model="order_filter_type">
                      <option value="mobile">หมายเลขโทรศัพท์</option>
                      <option value="payment_ref">เลขที่ใบเสร็จ</option>
                      <option value="uuid">เลขที่ใบสมัคร</option>
                      <option value="status">สถานะ</option>
                      <option value="email">อีเมลล์</option>
                      <option value="delivery_add_name">ชื่อผู้สมัคร</option>
                    </select>
                  </div>
                  &nbsp;
                  <div class="form-group">
                    <input
                      class="form-control"
                      v-model="order_filter_value"
                      type="text"
                      minlength="3"
                      placeholder="คำค้นหา"
                    />
                  </div>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    @click.prevent="load_last_registration('')"
                    @submit.prevent="load_last_registration('')"
                    class="btn btn-primary"
                  >
                    <vue-feather type="filter" size="18"> </vue-feather>
                    ค้นหา</button
                  >&nbsp;&nbsp;
                  <button
                    type="reset"
                    @click.prevent="load_last_registration('INITIAL_HAVE_SLIP')"
                    class="btn btn-primary"
                  >
                    <vue-feather type="filter" size="18"> </vue-feather>
                    รอชำระ
                  </button>&nbsp;&nbsp;
                  <button
                    type="reset"
                    @click.prevent="clearLatestOrder()"
                    class="btn btn-primary"
                  >
                    <vue-feather type="refresh-cw" size="18"> </vue-feather>
                    ทั้งหมด
                  </button>
                </form>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center">ใบสมัคร</th>
                    <th style="text-align: center">สถานะ</th>
                    <th style="text-align: center">วันที่สมัคร</th>
                    <th style="text-align: center">ใบเสร็จ</th>
                    <th style="text-align: center">รับเงินเข้าระบบ</th>
                    <th style="text-align: center">ยอดเงิน</th>
                    <th style="text-align: center">เวลาที่โอนเงิน</th>
                    <th style="text-align: center">หมายเลขโทรศัพท์</th>
                    <th style="text-align: center">ชื่อผู้สมัคร</th>
                    	
                    <th style="text-align: left">อีเมลล์</th>
                    <th style="text-align: left">จำนวนผู้สมัคร</th>
                    <th style="text-align: left">การจัดส่ง</th>
                    <th style="text-align: left">แก้ไขข้อมูล</th>
                  </tr>
                </thead>
                <tbody v-if="this.is_loading_last_registration === 'Y'">
                  <tr>
                    <td colspan="15">
                      <div class="d-flex justify-content-center">
                        <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.report_last_registration.length !== 0">
                  <tr v-for="item in this.report_last_registration" :key="item">
                    <td style="text-align: center"><a target="_blank" :href=" '/order/' + item.uuid"><u><b>{{ item.uuid }}</b></u></a></td>
                    <td v-if="item.status === 'INITIAL'">
                        <font style="color: red;">รอชำระเงิน</font>
                      </td>
                      <td v-if="item.status === 'PAID'">
                        <font style="color: green;"><vue-feather stroke="green" type="check-circle" size="20"> </vue-feather> สำเร็จ</font>
                      </td>
                    <td style="text-align: center">{{ item.create_date }}</td>
                    <td style="text-align: center"><img :src=item.payment_slip_url style="width: 100px;" ></td>
                    <td v-if="item.status === 'INITIAL'" style="text-align: center"><button style="padding: 5px;" type="button" @click.prevent="direct_notify(item.uuid, item.mobile)" class="btn btn-primary" >
                              <vue-feather type="check-circle"  size="18"> </vue-feather> รับเงิน </button></td>
                    <td v-if="item.status !== 'INITIAL'" style="text-align: center" ><vue-feather stroke="green" type="check-circle" size="20"> </vue-feather></td>                              
                    <td style="text-align: center">{{ item.amount }}</td>
                    <td style="text-align: center">{{ item.payment_slip_time }}</td>
                    <td style="text-align: center">{{ item.mobile }}</td>
                    <td style="text-align: left">{{ item.delivery_add_name }}</td>
                    <td style="text-align: left">{{ item.email }}</td>
                    <td style="text-align: center">{{ item.runner_count }}</td>
                    <td style="text-align: left">{{ item.delivery_type_name }}</td>
                    <td style="text-align: left"><a target="_blank" :href=" '/order-update/' + this.eventDetails.event_key+'/'+item.uuid"><vue-feather type="edit" size="20"></vue-feather></a></td>
                  </tr>
                </tbody>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <div v-if="this.eventDetails.payment_method!= 'DIRECT'" class="col-xl-12">
          <div class="card">
            <b>[{{this.eventDetails.title }}]</b>
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"></h5>
                <form class="form-inline">
                  <div class="form-group">
                    <select class="form-select" v-model="order_filter_type">
                      <option value="mobile">หมายเลขโทรศัพท์</option>
                      <option value="payment_ref">เลขที่ใบเสร็จ</option>
                      <option value="uuid">เลขที่ใบสมัคร</option>
                      <option value="status">สถานะ</option>
                      <option value="email">อีเมลล์</option>
                    </select>
                  </div>
                  &nbsp;
                  <div class="form-group">
                    <input
                      class="form-control"
                      v-model="order_filter_value"
                      type="text"
                      minlength="3"
                      placeholder="คำค้นหา"
                    />
                  </div>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    @click.prevent="load_last_registration('')"
                    @submit.prevent="load_last_registration('')"
                    class="btn btn-primary"
                  >
                    <vue-feather type="filter" size="18"> </vue-feather>
                    Filter</button
                  >&nbsp;&nbsp;
                  <button
                    type="reset"
                    @click.prevent="clearLatestOrder()"
                    class="btn btn-primary"
                  >
                    <vue-feather type="refresh-cw" size="18"> </vue-feather>
                    Refresh
                  </button>
                </form>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center">ใบสมัคร</th>
                    <th style="text-align: center">สถานะ</th>
                    <th style="text-align: center">วันที่สมัคร</th>
                    <th style="text-align: center">จ่ายเงิน</th>
                    <th style="text-align: center">ยอดเงิน</th>
                    <th style="text-align: center">หมายเลขโทรศัพท์</th>
                    <th style="text-align: left">ชื่อผู้สมัคร</th>
                    <th style="text-align: left">อีเมลล์</th>
                    <th style="text-align: left">จำนวนผู้สมัคร</th>
                    <th style="text-align: left">การจัดส่ง</th>
                    <th style="text-align: left">แก้ไขข้อมูล</th>
                  </tr>
                </thead>
                <tbody v-if="this.is_loading_last_registration === 'Y'">
                  <tr>
                    <td colspan="15">
                      <div class="d-flex justify-content-center">
                        <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.report_last_registration.length !== 0">
                  <tr v-for="item in this.report_last_registration" :key="item">
                    <td style="text-align: center"><a target="_blank" :href=" '/order/' + item.uuid"><u><b>{{ item.uuid }}</b></u></a></td>
                    <td v-if="item.status === 'INITIAL'">
                        <font style="color: red;">รอชำระเงิน</font>
                      </td>
                      <td v-if="item.status === 'PAID'">
                        <font style="color: green;">ชำระเงินสำเร็จ</font>
                      </td>
                    <td style="text-align: left">{{ item.create_date }}</td>
                    <td style="text-align: center">{{ item.payment_date }}</td>
                    <td style="text-align: center">{{ item.amount }}</td>
                    <td style="text-align: center">{{ item.mobile }}</td>
                    <td style="text-align: left">{{ item.delivery_add_name }}</td>

                    <td style="text-align: left">{{ item.email }}</td>
                    <td style="text-align: center">{{ item.runner_count }}</td>
                    <td style="text-align: left">{{ item.delivery_type_name }}</td>
                    <td style="text-align: center"><a target="_blank" :href=" '/order-update/' + this.eventDetails.event_key+'/'+item.uuid"><vue-feather type="edit" size="20"></vue-feather></a></td>
                  </tr>
                </tbody>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>จำนวนผู้สมัคร แต่ละประเภท</b></h5>
                <button type="button" @click.prevent="load_runner_type()" class="btn btn-primary" >
                <vue-feather type="refresh-cw" size="18"> </vue-feather> Refresh
              </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center">ลำดับ</th>
                    <th style="text-align: left">ประเภทการเเข่งขัน</th>
                    <th style="text-align: center">จำนวนผู้สมัคร</th>
                  </tr>
                </thead>
                <tbody v-if="this.is_loading_runner_type === 'Y'">
                  <tr>
                    <td colspan="15">
                      <div class="d-flex justify-content-center">
                        <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.report_runner_type.length !== 0">
                  <tr v-for="item, index in this.report_runner_type" :key="item">
                    <td style="text-align: center">{{ index + 1 }}</td>
                    <td style="text-align: left">{{ item.run_type_name }}</td>
                    <td style="text-align: center">{{ item.count_number }}</td>
                  </tr>
                </tbody>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>จำนวนผู้สมัคร แต่ละประเภท แบงตามเพศ</b></h5>
                <button type="button" @click.prevent="load_runner_type_sex()" class="btn btn-primary" >
                <vue-feather type="refresh-cw" size="18"> </vue-feather> Refresh
              </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center">ลำดับ</th>
                    <th style="text-align: left">ประเภทการเเข่งขัน</th>
                    <th style="text-align: left">เพศ</th>
                    <th style="text-align: center">จำนวนผู้สมัคร</th>
                  </tr>
                </thead>
                <tbody v-if="this.is_loading_runner_type_sex === 'Y'">
                  <tr>
                    <td colspan="15">
                      <div class="d-flex justify-content-center">
                        <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.report_runner_type_sex.length !== 0">
                  <tr v-for="item, index in this.report_runner_type_sex" :key="item">
                    <td style="text-align: center">{{ index + 1 }}</td>
                    <td style="text-align: left">{{ item.run_type_name }}</td>
                    <td style="text-align: left">{{ item.sex }}</td>
                    <td style="text-align: center">{{ item.count_number }}</td>
                  </tr>
                </tbody >
                <tbody v-if="this.report_runner_type_sex.length === 0">
                  <tr>
                    <td colspan="15">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>สถานะ คำสั่งซื้อ</b></h5>
                <button type="button" @click.prevent="load_order_count()" class="btn btn-primary" >
                <vue-feather type="refresh-cw" size="18"> </vue-feather> Refresh
              </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center">ลำดับ</th>
                    <th style="text-align: left">สถานะ</th>
                    <th style="text-align: center">จำนวนคำสั่งซื้อ</th>
                  </tr>
                </thead>
                <tbody v-if="this.is_loading_order_count === 'Y'">
                  <tr>
                    <td colspan="15">
                      <div class="d-flex justify-content-center">
                        <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.report_order_count.length !== 0">
                  <tr v-for="item, index in this.report_order_count" :key="item">
                    <td style="text-align: center">{{ index + 1 }}</td>
                    <td v-if="item.status === 'INITIAL'" style="text-align: left;color: red;">รอชำระเงิน</td>
                    <td v-if="item.status === 'PAID'" style="text-align: left;color: green;">ชำระเงินสำเร็จ</td>
                    <td style="text-align: center">{{ item.count_number }}</td>
                  </tr>
                </tbody>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>รายได้ค่าสมัคร</b></h5>
                <button type="button" @click.prevent="load_order_amt()" class="btn btn-primary" >
                <vue-feather type="refresh-cw" size="18"> </vue-feather> Refresh
              </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center">ลำดับ</th>
                    <th style="text-align: left">ยอดรวมค่าสมัคร</th>
                    <th style="text-align: center">ยอดรวมค่าจัดส่ง</th>
                  </tr>
                </thead>
                <tbody v-if="this.is_loading_order_amt === 'Y'">
                  <tr>
                    <td colspan="15">
                      <div class="d-flex justify-content-center">
                        <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.report_order_amt.length !== 0">
                  <tr v-for="item, index in this.report_order_amt" :key="item">
                    <td style="text-align: center">{{ index + 1 }}</td>
                    <td style="text-align: left">{{ item.amount }}</td>
                    <td style="text-align: center">{{ item.delivery_type_price }}</td>
                  </tr>
                </tbody>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>Export รายงาน</b></h5>
                
              </div>
            </div>
            <div class="header">
              <button style="margin: 2px;" :disabled="this.is_loading_order_excel_all === 'Y' ? '' : disabled" type="button" @click="load_order_excel_all()" class="btn btn-primary" >
              <vue-feather v-if="this.is_loading_order_excel_all === 'Y'" type="loader" size="18" animation="spin"></vue-feather> Download ข้อมูลผู้สมัครทั้งหมด
            </button>
            <button style="margin: 2px;" :disabled="this.is_loading_order_excel_delivery === 'Y' ? '' : disabled" type="button" @click="load_order_excel_delivery()" class="btn btn-primary" >
              <vue-feather v-if="this.is_loading_order_excel_delivery === 'Y'" type="loader" size="18" animation="spin"></vue-feather> Download ข้อมูลผู้สมัคร ที่ต้องจัดส่งเสื้อ
            </button>
            <button style="margin: 2px;" :disabled="this.is_loading_order_excel_self === 'Y' ? '' : disabled" type="button" @click="load_order_excel_self()" class="btn btn-primary" >
              <vue-feather v-if="this.is_loading_order_excel_self === 'Y'" type="loader" size="18" animation="spin"></vue-feather> Download ข้อมูลผู้สมัคร มารับเสื้อด้วยตนเอง
            </button>
            </div>
          </div>
        </div>


        

      </div>
    </div>
  </div>
</template>

<script>
import welcomecard from "./user_welcomecard.vue";
import axios from "axios";
export default {
  components: {
    welcomecard,
  },
  data() {
    return {
      is_authorization: null,
      eventDetails: {},
      token: null,
      is_loading_runner_type: "N",
      report_runner_type: [],

      is_loading_runner_type_sex: "N",
      report_runner_type_sex: [],

      order_filter_type: "mobile",
      order_filter_value: "",
      is_loading_last_registration: "N",
      report_last_registration: [],

      is_loading_order_count: "N",
      report_order_count: [],

      is_loading_order_amt: "N",
      report_order_amt: [],

      is_loading_direct_notify: "N",
      report_direct_notify: [],
      

      is_loading_order_excel_all: "N",
      is_loading_order_excel_delivery: "N",
      is_loading_order_excel_self: "N"
    }
  },
  async mounted() {
    this.token = localStorage.getItem("_rr_token");
    if(this.token !== null){
      await this.authorization()
      if(this.is_authorization === "Y"){
        this.getEventDetail(this.$route.params.event_key);
        this.clearLatestOrder();
        //this.load_runner_type();
        //this.load_runner_type_sex();
        //this.load_order_count();
        //this.load_order_amt();
      }
      

    }else{
      //alert("เซสชันหมดอายุ กรุณาเข้าสู่ระบบอีกครั้ง")
      this.$router.push("/auth/login");
    }
    
  },
  methods: {
    async authorization(_uuid,_mobile_no){
            const data = {
              event_key: this.$route.params.event_key
            };
            const headers = {
              'Authorization': 'Bearer ' + this.token
            };
            const res = await axios.post("v1/organizer/authorization", data, { headers });
            if (res.data.status === 'success') {
              this.report_direct_notify = res.data.body
              this.is_authorization = res.data.message.is_authorization
            } else {
              alert("พบข้อผิดพลาด: "+res.data.message	)
            }
        },  
    async direct_notify(_uuid,_mobile_no){

      if(confirm("ยืนยันการรับชำระคำเงิน \nสั่งซื้อ: "+_uuid+" \nหมายเลขโทรศัพท์ "+_mobile_no+"")){
          const data = {
            event_key: this.$route.params.event_key,
            uuid: _uuid
          };
          const headers = {
            'Authorization': 'Bearer ' + this.token
          };
          const res = await axios.post("v1/organizer/direct-notify", data, { headers });
          if (res.data.status === 'success') {
            this.report_direct_notify = res.data.body
            this.is_loading_direct_notify = "N"
            this.clearLatestOrder('');
          } else {

            alert("พบข้อผิดพลาด: "+res.data.message	)
            this.is_loading_direct_notify = "N"
          }
      }

      

    },
    async getEventDetail(event_key) {
      const res = await axios.get("/v1/events/" + event_key);
      //console.log("res.data", JSON.stringify(res.data))
      if (res.data.status === "success") {
        this.eventDetails = res.data;
        // //this.eventPackage = res.data.package;

        // return res.data;
      } else {
        //alert("พบข้อผิดพลาด! ไม่พบ url ของกิจกรรม");
        router.push({ path: "/404" });
      }
    },
    async load_runner_type() {
      this.is_loading_runner_type = "Y"
      this.report_runner_type = [];
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      const res = await axios.post("v1/organizer/report-runner-type", data, { headers });
      //console.log("res:", res)
      if (res.data.status === 'success') {
        this.report_runner_type = res.data.body
        this.is_loading_runner_type = "N"
      } else {

        alert("พบข้อผิดพลาด")
        this.is_loading_runner_type = "N"
      }
    },
    async load_runner_type_sex() {
      this.is_loading_runner_type_sex = "Y"
      this.report_runner_type_sex = [];
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      const res = await axios.post("v1/organizer/report-runner-type-sex", data, { headers });
      //console.log("res:", res)
      if (res.data.status === 'success') {
        this.report_runner_type_sex = res.data.body
        this.is_loading_runner_type_sex = "N"
      } else {

        alert("พบข้อผิดพลาด")
        this.is_loading_runner_type_sex = "N"
      }
    },
    async clearLatestOrder(_filter) {
      this.order_filter_value = "";
      
      this.load_last_registration('');
    },
    async load_last_registration(_filter) {

      if (this.order_filter_value.length == 0 || this.order_filter_value.length >= 3){
        this.is_loading_last_registration = "Y"
        this.report_last_registration = [];
        let data = {
          event_key: this.$route.params.event_key,
          order_filter_type: this.order_filter_type,
          order_filter_value: this.order_filter_value
        };
        if(_filter === 'INITIAL_HAVE_SLIP'){
          data = {
          event_key: this.$route.params.event_key,
          order_filter_type: "INITIAL_HAVE_SLIP",
          order_filter_value: "spacial"
        };
        }
        const headers = {
          'Authorization': 'Bearer ' + this.token
        };
        
        const res = await axios.post("v1/organizer/report-order-latest", data, { headers });
        //console.log("res:", res)
        if (res.data.status === 'success') {
          this.report_last_registration = res.data.body
          this.is_loading_last_registration = "N"
        } else {

          alert("พบข้อผิดพลาด")
          this.is_loading_last_registration = "N"
        }
      }

      
    },
    async load_order_count() {
      this.is_loading_order_count = "Y"
      this.report_order_count = [];
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      const res = await axios.post("v1/organizer/report-order-count", data, { headers });
      //console.log("res:", res)
      if (res.data.status === 'success') {
        this.report_order_count = res.data.body
        this.is_loading_order_count = "N"
      } else {

        alert("พบข้อผิดพลาด")
        this.is_loading_order_count = "N"
      }
    },
    async load_order_amt() {
      this.is_loading_order_amt = "Y"
      this.report_order_amt = [];
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      const res = await axios.post("v1/organizer/report-order-amt", data, { headers });
      //console.log("res:", res)
      if (res.data.status === 'success') {
        this.report_order_amt = res.data.body
        this.is_loading_order_amt = "N"
      } else {

        alert("พบข้อผิดพลาด")
        this.is_loading_order_amt = "N"
      }
    },
    
    async load_order_excel_all(){

      this.is_loading_order_excel_all = "Y"
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      await axios({
          url: 'v1/organizer/report-order-excel-all',
          method: 'POST',
          data: data,
          responseType: 'blob',
          headers: headers
      }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          const dt = new Date();
          const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
          let txt_file = dt.getFullYear()+padL(dt.getMonth()+1)+padL(dt.getDate())+padL(dt.getHours())+padL(dt.getMinutes())+padL(dt.getSeconds())
         

          const excel_report_file_name = "[REPORT-ALL]"+this.$route.params.event_key+"-"+txt_file+".xlsx"
          link.setAttribute('download', excel_report_file_name); 
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.is_loading_order_excel_all = "N"
      });
      this.is_loading_order_excel_all = "N"
    },

    async load_order_excel_delivery(){

      this.is_loading_order_excel_delivery = "Y"
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };

      await axios({
          url: 'v1/organizer/report-order-excel-delivery', 
          method: 'POST',
          data: data,
          responseType: 'blob',
          headers: headers
      }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          const dt = new Date();
          const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
          let txt_file = dt.getFullYear()+padL(dt.getMonth()+1)+padL(dt.getDate())+padL(dt.getHours())+padL(dt.getMinutes())+padL(dt.getSeconds())
        

          const excel_report_file_name = "[REPORT-DELIVERY]"+this.$route.params.event_key+"-"+txt_file+".xlsx"
          link.setAttribute('download', excel_report_file_name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.is_loading_order_excel_delivery = "N"
      });
      this.is_loading_order_excel_delivery = "N"
    },
    async load_order_excel_self(){

      this.is_loading_order_excel_self = "Y"
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };

      await axios({
          url: 'v1/organizer/report-order-excel-self',
          method: 'POST',
          data: data,
          responseType: 'blob', 
          headers: headers
      }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          const dt = new Date();
          const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
          let txt_file = dt.getFullYear()+padL(dt.getMonth()+1)+padL(dt.getDate())+padL(dt.getHours())+padL(dt.getMinutes())+padL(dt.getSeconds())
        

          const excel_report_file_name = "[REPORT-SELF]"+this.$route.params.event_key+"-"+txt_file+".xlsx"
          link.setAttribute('download', excel_report_file_name);
          link.click();
          URL.revokeObjectURL(href);
          this.is_loading_order_excel_self = "N"
      });
      this.is_loading_order_excel_self = "N"
    }
  }
};
</script>
