<template>
  <Breadcrumbs title="" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header" v-if="this.event_register.title">
            <div class="d-flex justify-content-center">
              <figure class="col-xl-10 col-sm-12">
                <img :src="this.event_register.coverurl"  style="box-shadow: 0em 0em 1em rgb(0 0 0 / 50%);border-bottom-left-radius: 0.6em;border-bottom-right-radius: 0.6em;border: 0px;" alt="Image description"
                  class="img-thumbnail" />
              </figure>
            </div>
            <h3> {{ this.event_register.title }}</h3>
            <p>
              <b><i class="fa fa-calendar-check-o" aria-hidden="true"></i> {{this.currentLanguage ==='en'?'Event date':'วันที่จัดกิจกรรม'}}: {{ this.event_register.event_date_text }}
              </b>
              <br />
              <b>&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp; {{ this.event_register.location }} </b>
              <br />
            </p>
          </div>

          <div class="card-header" v-if="!this.event_register.title">
            <div class="d-flex justify-content-center">
              <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
            </div>
            <div class="d-flex justify-content-center">
              {{this.currentLanguage ==='en'?'Loading...':'กำลังโหลดข้อมูลกิจกรรม...'}}
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.event_register.is_registration_start === 'Y' && this.event_register.is_open_register === 'Y'" >
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h3>{{this.currentLanguage ==='en'?'Step':'ขั้นตอนการสมัคร'}}</h3>
              <div class="stepper-wrapper">
                <div class="stepper-item completed">
                  <div class="step-counter">1</div>
                  <div class="step-name">{{this.currentLanguage ==='en'?'Infomation':'ข้อมูลผู้สมัคร'}}</div>
                </div>
                <div class="stepper-item completed">
                  <div class="step-counter">2</div>
                  <div class="step-name">{{this.currentLanguage ==='en'?'Address':'ข้อมูลที่อยู่'}}</div>
                </div>
                <div class="stepper-item ">
                  <div class="step-counter">3</div>
                  <div class="step-name">{{this.currentLanguage ==='en'?'Payment':'ชำระเงิน'}}</div>
                </div>
                <div class="stepper-item">
                  <div class="step-counter">4</div>
                  <div class="step-name">{{this.currentLanguage ==='en'?'Completed':'เสร็จสมบูรณ์'}}</div>
                </div>
              </div>

            </div>

            <div class="card-body">
              <h3>2. {{this.currentLanguage ==='en'?'Registration form':'กรอกข้อมูลผู้เข้าแข่งขัน'}} <span style="color: red;">*</span></h3>
              <form @submit.prevent="addRunner" class="needs-validation">
                <p>&nbsp;</p>
                <h4>Participant info</h4>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="register_type" checked required type="radio"
                    name="inlineRadioOptions" id="inlineRadio1" value="MYSELF">
                  <label class="form-check-label" for="inlineRadio1">
                    <h5>{{this.currentLanguage ==='en'?'Myself':'สมัครให้ตัวเอง'}}</h5>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="register_type" type="radio" required name="inlineRadioOptions"
                    id="inlineRadio2" value="FRIEND">
                  <label class="form-check-label" for="inlineRadio2">
                    <h5>{{this.currentLanguage ==='en'?'For Friends':'สมัครให้เพื่อน'}}</h5>
                  </label>
                </div>

                <p>&nbsp;</p>
                <div class="col-sm-12">
                  <h4>{{this.currentLanguage ==='en'?'Personal Information':'ข้อมูลส่วนตัวผู้สมัคร'}}</h4>
                  <div class="row">
                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <input type="text" minlength="3" maxlength="40"  required v-model="fname" class="form-control" id="fname">
                        <label for="fname">{{this.currentLanguage ==='en'?'Name':'ชื่อ'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <input type="text" minlength="3" maxlength="40" required v-model="lname" class="form-control" id="lname">
                        <label for="lname">{{this.currentLanguage ==='en'?'Last Name':'นามสกุล'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <select class="form-select" required v-model="sex" id="sex" aria-label="Floating label select example">
                          <option value="MALE">{{this.currentLanguage ==='en'?'MALE':'ชาย'}}</option>
                          <option value="">{{this.currentLanguage ==='en'?'FEMALE':'หญิง'}}</option>
                        </select>
                        <label for="sex">{{this.currentLanguage ==='en'?'Gender':'เพศ'}}</label>
                      </div>
                    </div>

                    <div class="col-sm-4 col-lg-2 col-xl-1">
                      <div class="form-floating mb-3">
                        <select class="form-select" required v-model="dobd" id="floatingSelect"
                          aria-label="Floating label select example">
                          <option value="01" selected>1</option>
                          <option value="02">2</option>
                          <option value="03">3</option>
                          <option value="04">4</option>
                          <option value="05">5</option>
                          <option value="06">6</option>
                          <option value="07">7</option>
                          <option value="08">8</option>
                          <option value="09">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </select>
                        <label for="floatingSelect">{{this.currentLanguage ==='en'?'Date of Birth':'วันเกิด'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-xl-2">
                      <div class="form-floating mb-3">
                        <select class="form-select" required v-model="dobm" id="floatingSelect"
                          aria-label="Floating label select example">
                          <option value="01" selected>{{this.currentLanguage ==='en'?'January':'มกราคม'}}</option>
                          <option value="02">{{this.currentLanguage ==='en'?'February':'กุมภาพันธ์'}}</option>
                          <option value="03">{{this.currentLanguage ==='en'?'March':'มีนาคม'}}</option>
                          <option value="04">{{this.currentLanguage ==='en'?'April':'เมษายน'}}</option>
                          <option value="05">{{this.currentLanguage ==='en'?'May':'พฤษภาคม'}}</option>
                          <option value="06">{{this.currentLanguage ==='en'?'June':'มิถุนายน'}}</option>
                          <option value="07">{{this.currentLanguage ==='en'?'July':'กรกฎาคม'}}</option>
                          <option value="08">{{this.currentLanguage ==='en'?'August':'สิงหาคม'}}</option>
                          <option value="09">{{this.currentLanguage ==='en'?'September':'กันยายน'}}</option>
                          <option value="10">{{this.currentLanguage ==='en'?'October':'ตุลาคม'}}</option>
                          <option value="11">{{this.currentLanguage ==='en'?'November':'พฤศจิกายน'}}</option>
                          <option value="12">{{this.currentLanguage ==='en'?'December':'ธันวาคม'}}</option>
                        </select>
                        <label for="floatingSelect">{{this.currentLanguage ==='en'?'Month':'เดือนเกิด'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-xl-1">
                      <div class="form-floating mb-3">
                        <select class="form-select" required v-model="doby" id="floatingSelect"
                          aria-label="Floating label select example">
                          <option value="1934">พ.ศ. 2477</option>
                          <option value="1935">พ.ศ. 2478</option>
                          <option value="1936">พ.ศ. 2479</option>
                          <option value="1937">พ.ศ. 2480</option>
                          <option value="1938">พ.ศ. 2481</option>
                          <option value="1939">พ.ศ. 2482</option>
                          <option value="1940">พ.ศ. 2483</option>
                          <option value="1941">พ.ศ. 2484</option>
                          <option value="1942">พ.ศ. 2485</option>
                          <option value="1943">พ.ศ. 2486</option>
                          <option value="1944">พ.ศ. 2487</option>
                          <option value="1945">พ.ศ. 2488</option>
                          <option value="1946">พ.ศ. 2489</option>
                          <option value="1947">พ.ศ. 2490</option>
                          <option value="1948">พ.ศ. 2491</option>
                          <option value="1949">พ.ศ. 2492</option>
                          <option value="1950">พ.ศ. 2493</option>
                          <option value="1951">พ.ศ. 2494</option>
                          <option value="1952">พ.ศ. 2495</option>
                          <option value="1953">พ.ศ. 2496</option>
                          <option value="1954">พ.ศ. 2497</option>
                          <option value="1955">พ.ศ. 2498</option>
                          <option value="1956">พ.ศ. 2499</option>
                          <option value="1957">พ.ศ. 2500</option>
                          <option value="1958">พ.ศ. 2501</option>
                          <option value="1959">พ.ศ. 2502</option>
                          <option value="1960">พ.ศ. 2503</option>
                          <option value="1961">พ.ศ. 2504</option>
                          <option value="1962">พ.ศ. 2505</option>
                          <option value="1963">พ.ศ. 2506</option>
                          <option value="1964">พ.ศ. 2507</option>
                          <option value="1965">พ.ศ. 2508</option>
                          <option value="1966">พ.ศ. 2509</option>
                          <option value="1967">พ.ศ. 2510</option>
                          <option value="1968">พ.ศ. 2511</option>
                          <option value="1969">พ.ศ. 2512</option>
                          <option value="1970">พ.ศ. 2513</option>
                          <option value="1971">พ.ศ. 2514</option>
                          <option value="1972">พ.ศ. 2515</option>
                          <option value="1973">พ.ศ. 2516</option>
                          <option value="1974">พ.ศ. 2517</option>
                          <option value="1975">พ.ศ. 2518</option>
                          <option value="1976">พ.ศ. 2519</option>
                          <option value="1977">พ.ศ. 2520</option>
                          <option value="1978">พ.ศ. 2521</option>
                          <option value="1979">พ.ศ. 2522</option>
                          <option value="1980">พ.ศ. 2523</option>
                          <option value="1981">พ.ศ. 2524</option>
                          <option value="1982">พ.ศ. 2525</option>
                          <option value="1983">พ.ศ. 2526</option>
                          <option value="1984">พ.ศ. 2527</option>
                          <option value="1985">พ.ศ. 2528</option>
                          <option value="1986">พ.ศ. 2529</option>
                          <option value="1987">พ.ศ. 2530</option>
                          <option value="1988">พ.ศ. 2531</option>
                          <option value="1989">พ.ศ. 2532</option>
                          <option value="1990">พ.ศ. 2533</option>
                          <option value="1991">พ.ศ. 2534</option>
                          <option value="1992">พ.ศ. 2535</option>
                          <option value="1993">พ.ศ. 2536</option>
                          <option value="1994">พ.ศ. 2537</option>
                          <option value="1995">พ.ศ. 2538</option>
                          <option value="1996">พ.ศ. 2539</option>
                          <option value="1997">พ.ศ. 2540</option>
                          <option value="1998">พ.ศ. 2541</option>
                          <option value="1999">พ.ศ. 2542</option>
                          <option value="2000">พ.ศ. 2543</option>
                          <option value="2001">พ.ศ. 2544</option>
                          <option value="2002">พ.ศ. 2545</option>
                          <option value="2003">พ.ศ. 2546</option>
                          <option value="2004">พ.ศ. 2547</option>
                          <option value="2005">พ.ศ. 2548</option>
                          <option value="2006">พ.ศ. 2549</option>
                          <option value="2007">พ.ศ. 2550</option>
                          <option value="2008">พ.ศ. 2551</option>
                          <option value="2009">พ.ศ. 2552</option>
                          <option value="2010">พ.ศ. 2553</option>
                          <option value="2011">พ.ศ. 2554</option>
                          <option value="2012">พ.ศ. 2555</option>
                          <option value="2013">พ.ศ. 2556</option>
                          <option value="2014">พ.ศ. 2557</option>
                          <option value="2015">พ.ศ. 2558</option>
                          <option value="2016">พ.ศ. 2559</option>
                          <option value="2017">พ.ศ. 2560</option>
                          <option value="2018">พ.ศ. 2561</option>
                          <option value="2019">พ.ศ. 2562</option>
                          <option value="2020">พ.ศ. 2563</option>
                          <option value="2021">พ.ศ. 2564</option>
                          <option value="2022">พ.ศ. 2565</option>
                        </select>
                        <label for="floatingSelect">{{this.currentLanguage ==='en'?'Year(BE)':'ปีเกิด'}}</label>
                      </div>
                    </div>


                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <select class="form-select" required v-model="nationality" id="floatingSelect"
                          aria-label="Floating label select example">
                          <option selected>{{this.currentLanguage ==='en'?'Thai':'ไทย'}}</option>
                          <option value="1">{{this.currentLanguage ==='en'?'Other':'อื่น ๆ'}}</option>
                        </select>
                        <label for="floatingSelect">{{this.currentLanguage ==='en'?'Nationality':'สัญชาติ'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <input type="text" required class="form-control" :minlength="this.$route.params.event_key==='sichon16'? '1':'5'" max="20" v-model="id_card" 
                          id="floatingInput">
                        <label for="floatingInput">{{this.currentLanguage ==='en'?'ID card/Passport':'เลขบัตรประชาชน/พาสปอร์ต'}}{{ this.event_register.validate_idcard }}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <input type="email" maxlength="40" :required="this.event_register.validate_email==='Y'? '':required" class="form-control" v-model="email" id="floatingInput">
                        <label for="floatingInput">{{this.currentLanguage ==='en'?'Email':'อีเมลล์'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <input type="text" required autocomplete="off" inputmode="numeric" class="form-control" minlength="10" maxlength="10"  v-model="mobile_no" id="floatingInput">
                        <label for="floatingInput">{{this.currentLanguage ==='en'?'Mobile number':'หมายเลขโทรศัพท์'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <input type="text" class="form-control" v-model="health_problem" id="floatingInput">
                        <label for="floatingInput">{{this.currentLanguage ==='en'?'health problems':'ปัญหาสุขภาพ'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <select class="form-select" required v-model="blood_group" id="floatingSelect"
                          aria-label="Floating label select example">
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="AB">AB</option>
                          <option value="O">O</option>
                          <option value="OTHER">Other</option>
                        </select>
                        <label for="floatingSelect">{{this.currentLanguage ==='en'?'Blood group':'หมู่เลือด'}}</label>
                      </div>
                    </div>


                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <input type="text" minlength="3" maxlength="40" required class="form-control" v-model="emergency_name" id="floatingInput">
                        <label for="floatingInput">{{this.currentLanguage ==='en'?'Emergency contact name':'ชื่อผู้ติดต่อกรณีฉุกเฉิน'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <input type="text" minlength="3" maxlength="40"  required class="form-control" v-model="emergency_relation" id="floatingInput">
                        <label for="floatingInput">{{this.currentLanguage ==='en'?'Relationship':'ความสัมพันธ์'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 required col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <input type="text" minlength="10" maxlength="15" autocomplete="off" inputmode="numeric"  required class="form-control" v-model="emergency_mobile_no" id="floatingInput">
                        <label for="floatingInput">{{this.currentLanguage ==='en'?'Emergency contact phone number':'หมายเลขโทรศัพท์ผู้ติดต่อกรณีฉุกเฉิน'}}</label>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <select class="form-select" required id="floatingSelect" @change.prevent="load_run_model" v-model="run_type"
                          aria-label="Floating label select example">
                          <option  v-for="(event_type, index) in this.event_register.run_type" :key="index"
                            :value="event_type.id">> {{ event_type.name }} ราคา: {{ event_type.price }} THB {{ event_type.description }}</option>
                        </select>
                        <label for="floatingSelect">{{this.currentLanguage ==='en'?'Type':'ประเภทการเเข่งขัน'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <select class="form-select" required id="floatingSelect" v-model="t_shirt"
                          aria-label="Floating label select example">
                          <option v-for="(t_shirt_type, index) in this.event_register.t_shirt" :key="index"
                            :value="t_shirt_type.id">> {{ t_shirt_type.name }}"</option>
                        </select>
                        <label for="floatingSelect">{{this.currentLanguage ==='en'?'Shirt size':'ขนาดเสื้อ'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4" v-if="this.event_register.is_run_model==='Y'">
                      <div class="form-floating mb-3">
                        <select class="form-select" required id="floatingSelect" v-model="run_model"
                          aria-label="Floating label select example">
                          <option v-for="(item, index) in this.event_register.run_model" :key="index"
                            :value="item.id">> {{ item.name }}</option>
                        </select>
                        <label for="floatingSelect">{{this.currentLanguage ==='en'?'Group':'การเเข่งขัน'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4">
                      <div class="form-floating mb-3">
                        <input type="text" class="form-control" v-model="runner_team" id="floatingInput">
                        <label for="floatingInput">{{this.currentLanguage ==='en'?'Team name':'ชื่อทีม(ถ้ามี)'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-4" v-if="this.event_register.is_additional1==='Y'" >
                      <div class="form-floating mb-3" v-for="(item, index) in this.event_register.additional1" :key="index" >
                        <select class="form-select" :required="this.event_register.is_additional1_require==='Y'" id="floatingSelect" v-model="additional1"
                          aria-label="Floating label select example">
                          <option v-for="(sub_item, sub_index) in this.event_register.additional1_list" :key="sub_index"
                            :value="sub_item.id">> {{ sub_item.name }}</option>
                        </select>
                        <label for="floatingSelect">{{ item.name }}</label>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-6 col-xl-4" v-if="this.event_register.is_additional2==='Y'" >
                      <div class="form-floating mb-3" v-for="(item, index) in this.event_register.additional2" :key="index" >
                        <select class="form-select" :required="this.event_register.is_additional2_require==='Y'" id="floatingSelect" v-model="additional2"
                          aria-label="Floating label select example">
                          <option v-for="(sub_item, sub_index) in this.event_register.additional2_list" :key="sub_index"
                            :value="sub_item.id">> {{ sub_item.name }}</option>
                        </select>
                        <label for="floatingSelect">{{ item.name }}</label>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-6 col-xl-4" v-if="this.event_register.is_additional3==='Y'" >
                      <div class="form-floating mb-3" v-for="(item, index) in this.event_register.additional3" :key="index" >
                        <select class="form-select" :required="this.event_register.is_additional3_require==='Y'" id="floatingSelect" v-model="additional3"
                          aria-label="Floating label select example">
                          <option v-for="(sub_item, sub_index) in this.event_register.additional3_list" :key="sub_index"
                            :value="sub_item.id">> {{ sub_item.name }}</option>
                        </select>
                        <label for="floatingSelect">{{ item.name }}</label>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-6 col-xl-4" v-if="this.event_register.is_additional4==='Y'" >
                      <div class="form-floating mb-3" v-for="(item, index) in this.event_register.additional4" :key="index" >
                        <select class="form-select" :required="this.event_register.is_additional4_require==='Y'" id="floatingSelect" v-model="additional4"
                          aria-label="Floating label select example">
                          <option v-for="(sub_item, sub_index) in this.event_register.additional4_list" :key="sub_index"
                            :value="sub_item.id">> {{ sub_item.name }}</option>
                        </select>
                        <label for="floatingSelect">{{ item.name }}</label>
                      </div>
                    </div>

                    <!-- additional -->
                    <!-- <div class="col-sm-12 col-lg-6 col-xl-4" v-for="(this.item, index) in this.event_register.additional" :key="index" >
                      <div class="form-floating mb-3">xxxxxxxxxxx{{ item.name_key	 }}
                        <select class="form-select" :required="item.is_require==='Y'" id="floatingSelect" v-model="item.name_key"
                          aria-label="Floating label select example">
                          <option v-for="(sub_item, sub_index) in item.list" :key="sub_index"
                            :value="sub_item.id">> {{ sub_item.name }}</option>
                        </select>
                        <label for="floatingSelect">{{ item.name }}</label>
                      </div>
                    </div> -->

                    <!-- addd -->
                  </div>
                  <p>&nbsp;</p>
                  <div class="row">
                    <div class="d-flex justify-content-center">
                      <button v-if="this.runner_action === 'EDIT'" type="submit" style="cursor: pointer"
                        class="btn btn-primary btn-lg"> {{this.currentLanguage ==='en'?'Update':'อัปเดทข้อมูลผู้สมัคร'}} </button>
                      <button v-if="this.runner_action === 'ADD'" type="submit" style="cursor: pointer" :disabled="this.event_register.max_runner_register_per_order === this.runnerList.length ? '' : disabled"
                        class="btn btn-primary btn-lg" > {{this.currentLanguage ==='en'?'Add':'เพิ่มข้อมูลผู้สมัคร'}} </button> &nbsp;
                      <button style="cursor: pointer" type="reset" @click.prevent="clear"
                        class="btn btn-secondary btn-lg"> {{this.currentLanguage ==='en'?'Cancel':'ยกเลิก'}}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <form @submit.prevent="create_order" class="needs-validation">
          <div class="col-sm-12" v-if="this.runnerList.length > 0">
            <div class="card">
              <div class="card-header">
                <h4>{{this.currentLanguage ==='en'?'Number of applicants information':'ข้อมูลผู้สมัครจำนวน'}} {{ this.runnerList.length }} {{this.currentLanguage ==='en'?'items':'ท่าน'}}</h4>
                <div class="row">

                  <div class="card" style="width: 18rem;" v-for="(item, index) in this.runnerList" :key="index"
                    :value="item.id_card">


                    <div style="text-align: right;"><button type="button" class="btn-close float-right"
                        @click.prevent="runner_delete(item.id_card, item.fname)" aria-label="Close"></button></div>
                    <div style="text-align: left;">
                      <input class="form-check-input" @click="select_main_register(item.id_card)"
                        v-model="is_main_register" required type="radio" name="is_main_register"
                        :id="'is_main_register' + item.id_card" :value="item.id_card">
                      <label class="form-check-label" :for="'is_main_register' + item.id_card">
                        <h5>&nbsp;{{this.currentLanguage ==='en'?'Main register':'ผู้สมัครหลัก'}}</h5>
                      </label>
                    </div>

                    <img src="../../assets/images/runner.jpg" class="card-img-top" alt="runner">
                    <div class="card-body">
                      <h5 class="card-title">{{ item.fname }} {{ item.lname }}</h5>
                      <p class="card-text">{{ item.run_type_name }}<br> {{ item.run_type_price }} THB <br> {{item.run_type_description }}

                      </p>
                      <p>
                        <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                          :data-bs-target="'#' + item.id_card"> {{this.currentLanguage ==='en'?'More':'เพิ่มเติม'}} </button> &nbsp;
                        <button type="button" class="btn btn-light btn-sm" @click.prevent="runner_edit(item.id_card)">
                          {{this.currentLanguage ==='en'?'Edit':'แก้ไข'}} </button>
                      </p>

                      <div class="modal fade" :id="item.id_card" tabindex="-1" :aria-labelledby="item.id_card"
                        aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h1 class="modal-title fs-5" :id="item.id_card">{{ item.fname }} {{ item.lname }}</h1>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              {{this.currentLanguage ==='en'?'name':'ชื่อ'}}: {{ item.fname }} {{ item.lname }} <br>
                              {{this.currentLanguage ==='en'?'gender':'เพศ'}}: {{ item.sex }} <br>
                              {{this.currentLanguage ==='en'?'dob':'วันเกิด'}}: {{ item.dob }} <br>
                              {{this.currentLanguage ==='en'?'nationality':'สัญชาติ'}}: {{ item.nationality }} <br>
                              {{this.currentLanguage ==='en'?'id card/Passport':'เลขบัตรประชาชน/พาสปอร์ต'}}: {{ item.id_card }} <br>
                              {{this.currentLanguage ==='en'?'mobile no':'หมายเลขโทรศัพท์'}}: {{ item.mobile_no }} <br>
                              {{this.currentLanguage ==='en'?'email':'อีเมลล์'}}: {{ item.email }} <br>
                              {{this.currentLanguage ==='en'?'blood type':'หมู่เลือด'}}: {{ item.blood_group }} <br>
                              {{this.currentLanguage ==='en'?'emergency contact name':'ชื่อผู้ติดต่อกรณีฉุกเฉิน'}}: {{ item.emergency_name }} <br>
                              {{this.currentLanguage ==='en'?'relationship':'ความสัมพันธ์'}}: {{ item.emergency_relation }} <br>
                              {{this.currentLanguage ==='en'?'emergency contact mobile number':'เบอร์โทรศัพท์ผู้ติดต่อกรณีฉุกเฉิน'}}: {{ item.emergency_mobile_no }} <br>
                              {{this.currentLanguage ==='en'?'Type':'ประเภทการเเข่งขัน'}}: {{ item.run_type_name }}, {{ item.run_type_description }}, {{ item.run_type_price }} <br>

                              {{this.currentLanguage ==='en'?'Group':'รูปแบบการแข่งขัน'}}: {{ item.run_model_name }} <br>
                              {{this.currentLanguage ==='en'?'Team':'ชื่อทีม'}}: {{ item.runner_team }} <br>
                              {{this.currentLanguage ==='en'?'Shirt size':'ขนาดเสื้อ'}}: {{ item.t_shirt_name }}<br>
                              {{this.currentLanguage ==='en'?'more':'เพิ่มเติม'}}: {{ item.additional1_name }}<br>
                              {{this.currentLanguage ==='en'?'more':'เพิ่มเติม'}}: {{ item.additional2_name }}<br>
                              <!-- เพิ่มเติม: {{ item.additional3_name }}<br>
                              เพิ่มเติม: {{ item.additional4_name }}<br> -->
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>


                <h4> {{this.currentLanguage ==='en'?'Shipping information':'ข้อมูลการจัดส่ง'}}</h4>
                <div class="form-check form-check-inline" v-for="(item, index) in this.event_register.delivery_type"
                  :key="index" :value="item.id">

                  <input class="form-check-input" @click="delivery_type_change(item.id)" v-model="delivery_type" required
                    type="radio" name="delivery_type_name" :id="'delivery_type' + item.id" :value="item.id">
                  <label class="form-check-label" :for="'delivery_type' + item.id">
                    <h5>{{ item.name }}<p style="size: 8px;color: gray;font-weight: normal;">{{ item.description}}</p></h5>
                  </label>

                </div>
                <div class="row" v-if="this.delivery_type_is_require_address === 'Y'">
                  <b> {{this.currentLanguage ==='en'?'Shipping Address':'ที่อยู่สำหรับการจัดส่ง'}}</b>
                  <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <input type="text" required v-model="delivery_add_name" class="form-control" id="floatingInput">
                      <label for="floatingInput">{{this.currentLanguage ==='en'?'First Name-Last Name':'ชื่อ-สกุล ผู้รับ'}}</label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <input type="text" required autocomplete="off" inputmode="numeric" v-model="delivery_add_mobile_no" class="form-control"
                        id="floatingInput">
                      <label for="floatingInput">{{this.currentLanguage ==='en'?'Mobile number':'หมายเลขโทรศัพท์'}}</label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <input type="text" required v-model="delivery_add_no" class="form-control" id="floatingInput">
                      <label for="floatingInput">{{this.currentLanguage ==='en'?'No./alley/road':'บ้านเลขที่/ซอย/ ถนน'}}</label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <input type="number" required v-model="delivery_add_postcode" autocomplete="off" inputmode="numeric"
                        @change="load_sub_district" class="form-control" minlength="5" maxlength="5" id="floatingInput">
                      <label for="floatingInput">{{this.currentLanguage ==='en'?'postcode':'รหัสไปรษณีย์'}}</label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <select class="form-select" v-model="delivery_add_sub_district" @change="load_district"
                        id="delivery_add_sub_district_id" aria-label="Floating label select example">
                        <option v-for="(sub_district, index) in this.delivery_add_sub_district_obj" :key="index"
                          :value="sub_district.ref"> {{ sub_district.name_th }}</option>
                      </select>
                      <label for="delivery_add_sub_district_id"> {{this.currentLanguage ==='en'?'Sub District':'ตำบล'}}</label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <select class="form-select" v-model="delivery_add_district" @change="load_province"
                        id="delivery_add_district_id" aria-label="Floating label select example">
                        <option v-for="(district, index) in this.delivery_add_district_obj" :key="index"
                          :value="district.ref">
                          {{ district.name_th }}</option>
                      </select> <label for="delivery_add_district_id">{{this.currentLanguage ==='en'?'District':'อำเภอ'}}</label>
                    </div>
                  </div>
                  
                  <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <select class="form-select" v-model="delivery_add_province" @change="load_province"
                        id="delivery_add_province_id" aria-label="Floating label select example">
                        <option v-for="(province, index) in this.delivery_add_province_obj" :key="index"
                          :value="province.ref">
                          {{ province.name }}</option>
                      </select>
                      <label for="delivery_add_province_id">{{this.currentLanguage ==='en'?'Province':'จังหวัด'}}</label>
                    </div>
                  </div>

                </div>


                <div v-if="this.event_register.payment_method !== 'DIRECT' && this.event_register.payment_method !== 'FREE'">
                  <h4>{{this.currentLanguage ==='en'?'Payment method':'ช่องทางการชำระเงิน'}}</h4>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" v-model="payment_method" required type="radio"
                      name="inlineRadioOptionspayment_method" id="payment_method1" :value="'PP_'+this.event_register.payment_method">
                    <label class="form-check-label" for="payment_method1">
                      <h5>{{this.currentLanguage ==='en'?'PromptPay':'พร้อมเพย์ (PromptPay)'}}</h5>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" v-model="payment_method" type="radio" required
                      name="inlineRadioOptionspayment_method" id="payment_method2" :value="'CC_'+this.event_register.payment_method">
                    <label class="form-check-label" for="payment_method2">
                      <h5>{{this.currentLanguage ==='en'?'Credit Card/debit':'บัตรเครดิต/เดบิต(Credit Card/debit)'}}</h5>
                    </label>
                  </div>
                </div>
                <p>&nbsp;</p>
                <h4>{{this.currentLanguage ==='en'?'Terms and Conditions of Use':'เงื่อนไขและข้อตกลงการใช้งานการใช้งาน'}}</h4>


                <div class="form-check">
                  <input class="form-check-input" required type="checkbox" value="" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                     {{this.currentLanguage ==='en'?'I have verified the accuracy of the application.':'ข้าพเจ้าได้ตรวจสอบความถูกต้องของใบสมัครเรียบร้อยแล้ว'}}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" required type="checkbox" value="" id="flexCheckChecked">
                  <label class="form-check-label" for="flexCheckChecked">
                     {{this.currentLanguage ==='en'?'I have understood and accepted the conditions of participation in the activity.':'ข้าพเจ้าได้ทำความเข้าใจ และยอมรับเงื่อนไขการเข้าร่วมกิจกรรม'}}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12" v-if="this.runnerList.length > 0 && this.event_register.payment_method !== 'FREE'">
            <div class="card">
              <div class="card-header">
                <h4>{{this.currentLanguage ==='en'?'Summary':'สรุปรายการสั่งซื้อ'}}</h4>

                <div class="table-responsive" style="margin-left: auto; margin-right: 0">
                  <table class="table align-to-right" style="width: 100%; text-align: center">
                    <thead>
                      <tr class="border-bottom-light">
                        <th scope="col" style="text-align: right; width: 50%">

                          {{this.currentLanguage ==='en'?'Application':'ค่าสมัคร'}}

                        </th>
                        <th scope="col" style="text-align: right">
                          {{ this.total_amt }} THB
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-bottom-light">
                        <th style="text-align: right">
                          {{this.currentLanguage ==='en'?'Shipping':'ค่าจัดส่ง'}}
                        </th>
                        <th style="text-align: right; vertical-align: bottom">
                          <p>
                            {{ this.delivery_type_price }} THB
                          </p>
                        </th>
                      </tr>
                    </tbody>

                    <tbody>
                      <tr class="border-bottom-dark">
                        <th scope="col" style="text-align: right">
                          {{this.currentLanguage ==='en'?'Total':'ยอดรวมหลังหักส่วนลด'}}
                        </th>
                        <th scope="col" style="text-align: right">
                          {{ Number(this.total_amt) + Number(this.delivery_type_price) }}
                          THB
                        </th>
                      </tr>
                    </tbody>

                    <tbody>
                      <tr class="border-bottom-white">
                        <th scope="col" style="text-align: right">
                          {{this.currentLanguage ==='en'?'fees':'ค่าธรรมเนียมธนาคาร และ อื่นๆ'}}
                          
                        </th>
                        <th v-if="this.event_register.payment_method !== 'DIRECT'" scope="col" style="text-align: right">
                          {{ Number(Number(this.total_amt*0.03) + Number(this.delivery_type_price*0.03)).toFixed(2) }}
                          THB
                        </th>
                        <th v-if="this.event_register.payment_method === 'DIRECT'" scope="col" style="text-align: right">
                          0 THB
                        </th>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr class="border-bottom-primary">
                        <th scope="col" style="text-align: right">
                          {{this.currentLanguage ==='en'?'Net payment':'ยอดชำระสุทธิ'}}
                        </th>
                        <th v-if="this.event_register.payment_method !== 'DIRECT'" scope="col" style="text-align: right">
                          <b>
                            {{ Number((Number(this.total_amt) + Number(this.delivery_type_price)) + Number(Number(this.total_amt*0.03) + Number(this.delivery_type_price*0.03))).toFixed(2) }}
                            THB</b>
                        </th>
                        <th v-if="this.event_register.payment_method === 'DIRECT'" scope="col" style="text-align: right">
                          <b>
                            {{ (Number(this.total_amt) + Number(this.delivery_type_price)) }}
                            THB</b>
                        </th>
                      </tr>
                    </tbody>
                  </table>


                </div>
                
                <!-- now_tmp:{{ this.now }}
                runner_count: {{ this.runner_count }} -->
                <div class="card">
                  <p>&nbsp;</p>
                  <div class="d-flex justify-content-center">
                    <button :disabled="this.is_submit_cart==='Y' ? '' : disabled" type="submit" style="cursor: pointer" class="btn btn-primary btn-lg"> <vue-feather type="loader" size="20" animation="spin" v-if="this.is_submit_cart === 'Y'" ></vue-feather>{{this.currentLanguage ==='en'?'Payment':'ชำระเงิน'}} </button>
                    &nbsp;
                    <button :disabled="this.is_submit_cart==='Y' ? '' : disabled" style="cursor: pointer" type="reset" @click.prevent="clear"
                      class="btn btn-secondary btn-lg">{{this.currentLanguage ==='en'?'Cancel':'ยกเลิก'}} </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12" v-if="this.runnerList.length > 0 && this.event_register.payment_method === 'FREE'">
            <div class="card">
              <div class="card-header">
                
                 
                  <div class="d-flex justify-content-center">
                    <button :disabled="this.is_submit_cart==='Y' ? '' : disabled" type="submit" style="cursor: pointer" class="btn btn-primary btn-lg"> <vue-feather type="loader" size="20" animation="spin" v-if="this.is_submit_cart === 'Y'" ></vue-feather>ลงทะเบียน </button>
                    &nbsp;
                    <button :disabled="this.is_submit_cart==='Y' ? '' : disabled" style="cursor: pointer" type="reset" @click.prevent="clear"
                      class="btn btn-secondary btn-lg">ยกเลิก </button>
                  </div>
      
              </div>
            </div>
          </div>


        </form>
      </div>
      <div v-if="this.event_register.is_registration_start === 'N' || this.event_register.is_open_register === 'N'" >
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="d-flex justify-content-center">
             <h5> เปิดให้ลงทะเบียนเร็วๆ นี้....</h5>
            </div>
          </div>
          
          </div>
          
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import router from "../../router";
import axios from "axios";
import { computed, reactive, ref } from "vue";
import { useHead } from "@vueuse/head";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';
//import { useRunnerStore } from "../../store/modules/runner";
//const now_tmp = computed(() => Date.now())

export default {
  setup() {
    const siteData = reactive({
      title: `ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH`,
      description: `ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH`,
      keywords: `ระบบรับสมัครงานวิ่ง,RUNRACE.IN.TH`,
      image: `-`,
    });

    useHead({
      // Can be static or computed this.event_register.title
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords),
        },
        {
          property: `og:image`,
          content: computed(() => siteData.image),
        },
      ],
    });

    //const runner_store = useRunnerStore();
    //return { runner_store };
  },

  data() {
    return {
      isFoundImage: "true",
      event_register: {},
      eventPackage: {},
      provide: "0",
      runnerList: [],
      is_add_runner: "N",
      register_type: "MYSEFT",
      fname: "",
      lname: "",
      sex: null,
      dob: null,
      dobd: null,
      dobm: null,
      doby: null,
      nationality: null,
      id_card: null,
      email: "",
      mobile_no: "",
      health_problem: "-",
      runner_team: "-",
      blood_group: null,
      emergency_name: null,
      emergency_relation: null,
      emergency_mobile_no: null,
      run_type: 0,
      run_model:  0,
      t_shirt: 0,
      delivery_type: 0,
      delivery_type_id: 0,
      delivery_type_name: "",
      delivery_type_price: 0,
      delivery_type_is_require_address: null,
      delivery_add_name: null,
      delivery_add_mobile_no: null,
      delivery_add_postcode: null,
      delivery_add_province_obj: [],
      delivery_add_province: null,
      delivery_add_district_obj: [],
      delivery_add_district: null,
      delivery_add_sub_district_obj: [],
      delivery_add_sub_district: null,
      delivery_add_no: null,
      total_amt: 0,
      paid_amt: 0,
      runner_action: "ADD",
      payment_method: null,
      is_main_register: null,
      is_submit_cart: "N",

      additional1: 0,
      additional2: 0,
      additional3: 0,
      additional4: 0,
      item: [],
      currentLanguage: 'th'

    };
  },
  async created() {
    if(localStorage.getItem('currentLanguage') !== null){
      this.currentLanguage = localStorage.getItem('currentLanguage');
    }
    await this.getEventDetail(this.$route.params.event_key);
    // if(this.$route.params.event_key ==='sichon16'){
    //   this.id_card = "-"
    // }
  },
  
  async mounted() {
    await this.getEventDetail(this.$route.params.event_key);
    if (localStorage.getItem('_runner_list') !== null) {
      this.runnerList = JSON.parse(localStorage.getItem('_runner_list'))
    }


    this.cal_cart_runner();
    this.define_main_register();
    //this.siteData.image = _data.coverurl ;
    //this.siteData.title = _data.title +' '+this.siteData.title ;
    //this.siteData.description = _data.title +' วันที่ '+_data.event_date_text+' ณ.'+_data.province+' '+this.siteData.description ;
  },
  methods: {
    async load_run_model() {
      const res = await axios.post("/v1/utils/run-model", {
        events_run_type_id: this.run_type,
        language: "TH"
      });
      if (res.data.status === "success") {
        this.event_register.run_model = res.data.body;
      }
    },
    async load_sub_district() {
      //console.log(this.delivery_add_postcode)
      const res = await axios.post("/v1/utils/sub-district", {
        id: this.delivery_add_postcode,
        language: "TH"
      });
      if (res.data.status === "success") {
        this.delivery_add_sub_district_obj = res.data.body;
        this.delivery_add_district_obj = []
        this.delivery_add_district = null
        this.delivery_add_province_obj = []
        this.delivery_add_province = []

      }
    },
    async load_district() {
      const res = await axios.post("/v1/utils/district", {
        id: this.delivery_add_sub_district.substring(0, this.delivery_add_sub_district.indexOf("_")),
        language: "TH"
      });
      if (res.data.status === "success") {
        this.delivery_add_district_obj = res.data.body;
      }
    },
    async load_province() {
      const res = await axios.post("/v1/utils/province", {
        id: this.delivery_add_district.substring(0, this.delivery_add_district.indexOf("_")),
        language: "TH"
      });
      if (res.data.status === "success") {
        this.delivery_add_province_obj = res.data.body;
        this.delivery_add_province = res.data.body[0].ref;
      }
    },
    async create_order() {
      this.is_submit_cart = "Y";
      if (this.event_register.payment_method === 'DIRECT') {
        this.paid_amt = (Number(this.total_amt) + Number(this.delivery_type_price))
      } else {
        this.paid_amt = Number((Number(this.total_amt) + Number(this.delivery_type_price)) + Number(Number(this.total_amt*0.03) + Number(this.delivery_type_price*0.03))).toFixed(2)
      }

      let _id_card, _fname, _lname, _mobile_no, _email

      for (let item_runner of this.runnerList) {
        if (item_runner.id_card === this.is_main_register) {
          _id_card = item_runner.id_card
          _fname = item_runner._fname
          _lname = item_runner._lname
          _mobile_no = item_runner.mobile_no
          _email = item_runner.email

        }
      }

      //console.log("this.paid_amt:"+this.paid_amt)
      let data = {
        runner_list: this.runnerList,
        delivery_type_id: this.delivery_type,
        delivery_type_name: this.delivery_type_name,
        delivery_type_price: this.delivery_type_price,
        delivery_type_is_require_address: this.delivery_type_is_require_address === null ? "N" : this.delivery_type_is_require_address,
        delivery_add_no: this.delivery_add_no,
        delivery_add_name: this.delivery_add_name,
        delivery_add_mobile_no: this.delivery_add_mobile_no,
        delivery_add_postcode: this.delivery_add_postcode,
        delivery_add_province: this.delivery_add_province,
        delivery_add_district: this.delivery_add_district === null ? null : this.delivery_add_district.substring(this.delivery_add_district.indexOf("_") + 1, this.delivery_add_district.length),
        delivery_add_sub_district: this.delivery_add_sub_district === null ? null : this.delivery_add_sub_district.substring(this.delivery_add_sub_district.indexOf("_") + 1, this.delivery_add_sub_district.length),
        total_amt: (Number(this.total_amt) + Number(this.delivery_type_price)).toFixed(2),
        paid_amt: this.paid_amt,
        //payment_method: this.event_register.payment_method,
        is_main_register: this.is_main_register,
        id_card: _id_card,
        fname: _fname,
        lname: _lname,
        mobile_no: _mobile_no,
        email: _email,
        runner_count: this.runnerList.length,
        event_key: this.$route.params.event_key,
        payment_account_no: this.event_register.payment_account_no,
        payment_account_name: this.event_register.payment_account_name,
        payment_bank_name: this.event_register.payment_bank_name,
        payment_method: this.event_register.payment_method,
        payment_method: this.payment_method===null?this.event_register.payment_method:this.payment_method

      }

      //console.log("data" + JSON.stringify(data))
      const res = await axios.post("/v1/order/create", data);
      //console.log("res:" + JSON.stringify(res))
      if (res.data.status === "success") {
        let _title = 'สมัครลงทะเบียนกิจกรรม เลขที่ใบสมัคร ' + res.data.uuid + ' เรียบร้อย'
        if(this.currentLanguage === 'en'){
          _title = 'Register completed orderID: ' + res.data.uuid 
        }

        this.is_submit_cart = "N";
        this.runnerList = [];
        localStorage.removeItem("_runner_list")
        await Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: _title,
          html: '<a href="/order/' + res.data.uuid + '"><button type="button" class="btn btn-success">ดูคำสั่งซื้อ/View</button></a>',
          showCloseButton: true,
          showConfirmButton: false
        })
      } else {
        this.is_submit_cart = "N";
        await Swal.fire({
          position: 'top-center',
          icon: 'error',
          title: 'พบข้อผิดพลาดระหว่างการสร้างรายการคำสั่งซื้อ กรุณาลองอีกครั้งหรือติดต่อเพจ RunRace',
          showConfirmButton: true,
          timer: 7000
        })
      }

    },
    async delivery_type_change(_id) {
      for (let item_delivery_type of this.event_register.delivery_type) {
        if (item_delivery_type.id === _id) {
          this.delivery_type_id = item_delivery_type.id
          this.delivery_type_name = item_delivery_type.name
          this.delivery_type_price = item_delivery_type.price
          this.delivery_type_is_require_address = item_delivery_type.is_require_address
        }
      }
    },
    async cal_cart_runner() {
      this.total_amt = 0
      for (let item of this.runnerList) {
        this.total_amt += item.run_type_price
      }


    },
    async getEventDetail(event_key) {
      const res = await axios.get("/v1/events/" + event_key);
      //console.log("res.data", JSON.stringify(res.data));
      if (res.data.status === "success") {
        this.event_register = res.data;
        this.event_register.run_model = []
        //this.eventPackage = res.data.package;

        return res.data;
      } else {
        //alert("พบข้อผิดพลาด! ไม่พบ url ของกิจกรรม");
        router.push({ path: "/404" });
      }
    },
    async select_main_register(_id_card) {
      this.is_main_register = _id_card
      this.define_main_register();
    },
    async define_main_register() {

      if (this.runnerList.length === 1) {
        let runner_main = this.runnerList[0];
        this.is_main_register = runner_main.id_card

        this.delivery_add_name = runner_main.fname + " " + runner_main.lname
        this.delivery_add_mobile_no = runner_main.mobile_no
      } else if (this.runnerList.length > 1) {
        for (let item_runner of this.runnerList) {
          if (this.is_main_register == item_runner.id_card) {
            this.delivery_add_name = item_runner.fname + " " + item_runner.lname
            this.delivery_add_mobile_no = item_runner.mobile_no
          }
        }

      }
    },
    async addRunner() {
      this.is_add_runner = "Y"


      if (this.runner_action === 'EDIT') {
        let new_runner_list = []
        for (let item_runner of this.runnerList) {
          if (item_runner.id_card === this.id_card) {
            for (let item_run_type of this.event_register.run_type) {
              if (item_run_type.id === this.run_type) {
                this.run_type_id = item_run_type.id
                this.run_type_name = item_run_type.name
                this.run_type_description = item_run_type.description
                this.run_type_price = item_run_type.price
              }
            }

            for (let item_t_shirt of this.event_register.t_shirt) {
              if (item_t_shirt.id === this.t_shirt) {
                this.t_shirt_id = item_t_shirt.id
                this.t_shirt_name = item_t_shirt.name
              }
            }

            for (let item_run_model of this.event_register.run_model) {
              if (item_run_model.id === this.run_model) {
                this.run_model_id = item_run_model.id
                this.run_model_name = item_run_model.name
              }
            }

            if(this.event_register.is_run_model === 'N'){
              this.run_model_id = 0
              this.run_model_name = '-'
            }

            for (let item_additional1 of this.event_register.additional1_list) {
              if (item_additional1.id === this.additional1) {
                this.additional1_id = item_additional1.id
                this.additional1_name = item_additional1.name
              }
            }

            for (let item_additional2 of this.event_register.additional2_list) {
              if (item_additional2.id === this.additional2) {
                this.additional2_id = item_additional2.id
                this.additional2_name = item_additional2.name
              }
            }

            for (let item_additional3 of this.event_register.additional3_list) {
              if (item_additional3.id === this.additional3) {
                this.additional3_id = item_additional3.id
                this.additional3_name = item_additional3.name
              }
            }

            for (let item_additional4 of this.event_register.additional4_list) {
              if (item_additional4.id === this.additional4) {
                this.additional4_id = item_additional4.id
                this.additional4_name = item_additional4.name
              }
            }

            
            const req = {
              fname: this.fname,
              lname: this.lname,
              sex: this.sex,
              dob: this.doby + "-" + this.dobm + "-" + this.dobd,
              nationality: this.nationality,
              id_card: this.id_card,
              email: this.email,
              mobile_no: this.mobile_no,
              health_problem: this.health_problem,
              runner_team: this.runner_team,
              blood_group: this.blood_group,
              emergency_name: this.emergency_name,
              emergency_relation: this.emergency_relation,
              emergency_mobile_no: this.emergency_mobile_no,
              run_type_id: this.run_type_id,
              run_type_name: this.run_type_name,
              run_model_id: this.run_model_id,
              run_model_name: this.run_model_name,
              additional1_id: this.additional1_id,
              additional2_id: this.additional2_id,
              additional3_id: this.additional3_id,
              additional4_id: this.additional4_id,
              additional1_name: this.additional1_name,
              additional2_name: this.additional2_name,
              additional3_name: this.additional3_name,
              additional4_name: this.additional4_name,
              run_type_description: this.run_type_description,
              run_type_price: this.run_type_price,
              t_shirt_id: this.t_shirt_id,
              t_shirt_name: this.t_shirt_name,
            };
            new_runner_list.push(req)
          } else {
            new_runner_list.push(item_runner)
          }
          localStorage.setItem("_runner_list", JSON.stringify(new_runner_list))
          this.runnerList = JSON.parse(localStorage.getItem('_runner_list'))
          this.cal_cart_runner();

          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'อัปเดทข้อมูล สำเร็จ',
            showConfirmButton: false,
            timer: 1500
          })
        }
      } else {
        if (this.runnerList.filter(runner => runner.id_card == this.id_card).length == 1) {
        let txt = 'คุณได้เพิ่มนักวิ่งท่านนี้ไปแล้ว<br> เลขบัตรประชาชน/พาสปอร์ต: ' + this.id_card
        if(this.currentLanguage === 'en'){
          txt = 'You have added this runner<br> ID card/Passport: ' + this.id_card
        }
          Swal.fire({
            position: 'top-center',
            icon: 'warning',
            title: txt,
            showConfirmButton: true,
            timer: 15000
          })
        } else {
          for (let item_run_type of this.event_register.run_type) {
            if (item_run_type.id === this.run_type) {
              this.run_type_id = item_run_type.id
              this.run_type_name = item_run_type.name
              this.run_type_description = item_run_type.description
              this.run_type_price = item_run_type.price
            }
          }

          for (let item_t_shirt of this.event_register.t_shirt) {
            if (item_t_shirt.id === this.t_shirt) {
              this.t_shirt_id = item_t_shirt.id
              this.t_shirt_name = item_t_shirt.name
            }
          }
          for (let item_run_model of this.event_register.run_model) {
            if (item_run_model.id === this.run_model) {
              this.run_model_id = item_run_model.id
              this.run_model_name = item_run_model.name
            }
          }

          if(this.event_register.is_run_model === 'N'){
            this.run_model_id = 0
            this.run_model_name = '-'
          }

          console.log('this.event_register.additional1_list:'+this.event_register.additional1_list.length)
          console.log('this.event_register.additional2_list:'+this.event_register.additional2_list.length)
          console.log('this.event_register.additional3_list:'+this.event_register.additional3_list.length)
          for (let item_additional1 of this.event_register.additional1_list) {
            if (item_additional1.id === this.additional1) {
              this.additional1_id = item_additional1.id
              this.additional1_name = item_additional1.name
            }
          }

          for (let item_additional2 of this.event_register.additional2_list) {
            if (item_additional2.id === this.additional2) {
              this.additional2_id = item_additional2.id
              this.additional2_name = item_additional2.name
            }
          }

          for (let item_additional3 of this.event_register.additional3_list) {
            if (item_additional3.id === this.additional3) {
              this.additional3_id = item_additional3.id
              this.additional3_name = item_additional3.name
            }
          }

          for (let item_additional4 of this.event_register.additional4_list) {
            if (item_additional4.id === this.additional4) {
              this.additional4_id = item_additional4.id
              this.additional4_name = item_additional4.name
            }
          }


          
          const req = {
            fname: this.fname,
            lname: this.lname,
            sex: this.sex,
            dob: this.doby + "-" + this.dobm + "-" + this.dobd,
            nationality: this.nationality,
            id_card: this.id_card,
            email: this.email,
            mobile_no: this.mobile_no,
            health_problem: this.health_problem,
            runner_team: this.runner_team,
            blood_group: this.blood_group,
            emergency_name: this.emergency_name,
            emergency_relation: this.emergency_relation,
            emergency_mobile_no: this.emergency_mobile_no,
            run_type_id: this.run_type_id,
            run_type_name: this.run_type_name,
            run_model_id: this.run_model_id,
            run_model_name: this.run_model_name,
            additional1_id: this.additional1_id,
            additional2_id: this.additional2_id,
            additional3_id: this.additional3_id,
            additional4_id: this.additional4_id,
            additional1_name: this.additional1_name,
            additional2_name: this.additional2_name,
            additional3_name: this.additional3_name,
            additional4_name: this.additional4_name,
            run_type_description: this.run_type_description,
            run_type_price: this.run_type_price,
            t_shirt_id: this.t_shirt_id,
            t_shirt_name: this.t_shirt_name,
          };

          this.runnerList.push(req)
          localStorage.setItem("_runner_list", JSON.stringify(this.runnerList))
          this.cal_cart_runner();
          
          
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'เพิ่มผู้สมัครเรียบร้อย/Completed',
            showConfirmButton: false,
            timer: 1500
          })

        }
      }
      this.define_main_register();
    },
    async runner_edit(_id_card) {
      this.runner_action = "EDIT"
      for (let item of this.runnerList) {
        if (item.id_card === _id_card) {
          this.fname = item.fname
          this.lname = item.lname
          this.sex = item.sex
          this.dobd = item.dob.substring(8, 10)
          this.dobm = item.dob.substring(5, 7)
          this.doby = item.dob.substring(0, 4)
          this.nationality = item.nationality
          this.id_card = item.id_card

          this.email = item.email
          this.mobile_no = item.mobile_no
          this.health_problem = item.health_problem
          this.runner_team = item.runner_team
          this.blood_group = item.blood_group

          this.emergency_name = item.emergency_name
          this.emergency_relation = item.emergency_relation
          this.emergency_mobile_no = item.emergency_mobile_no
          this.run_type = item.run_type_id
          this.run_model = item.run_model_id
          this.t_shirt = item.t_shirt_id
          this.additional1 = item.additional1_id
          this.additional2 = item.additional2_id
          this.additional3 = item.additional3_id
          this.additional4 = item.additional4_id

        }
      }
      this.cal_cart_runner();
      document.getElementById("fname").focus();
    },
    async runner_delete(_id_card, _fname) {
      if (confirm('Delete ' + _fname +' ?')) {
        let runner_list_tmp = []
        for (let item of this.runnerList) {
          if (_id_card !== item.id_card) {
            runner_list_tmp.push(item)
          }
        }
        this.runnerList = runner_list_tmp;
        localStorage.setItem("_runner_list", JSON.stringify(this.runnerList))
      }
      this.cal_cart_runner();
    },
    async clear() {
      this.register_type = "MYSEFT",
        this.runner_action = 'ADD'
      this.run_type = ""
      this.run_model = ""
      this.fname = ""
      this.lname = ""
      this.sex = ""
      this.dob = ""
      this.nationality = null
      this.id_card = null
      this.email = null
      this.mobile_no = null
      this.health_problem = null
      this.runner_team = ""
      this.blood_group = null
      this.emergency_name = null
      this.emergency_relation = null
      this.emergency_mobile_no = null
      this.run_type = null
      this.run_model = null
      this.additional1 = null
      this.additional2 = null
      this.additional3 = null
      this.additional4 = null
      this.t_shirt = null
    }
  },
};
</script>
<style scoped>
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #66b5ff;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #66b5ff;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}
</style>
